import React from 'react'
// import LogRocket from 'logrocket'
import Transition from './src/components/transition'

export const wrapPageElement = ({ element, props }) => <Transition {...props}>{element}</Transition>

export const onClientEntry = () => {
  // Initialise LogRocket error tracking
  // LogRocket.init('zfyerd/hamilton-massage-company')
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

const transitionDelay = 200

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay)
  }
  return false
}
